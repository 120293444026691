import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/cmsPages/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Kubernetes is a container orchestration system.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It lets you deploy, scale, and connect many containers with a simple .yml configuration file.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It's probably overkill if you only have a couple containers.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It's easy to use, but hard to setup and maintain, so probably use a hosted solution.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      